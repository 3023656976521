module.exports = {
  ga: "UA-143315929-1",
  app: {
    apiKey: "AIzaSyCMI_64rVApMviNEuzKsf77SoiR2-GYJ8Y",
    authDomain: "ganapass-web.firebaseapp.com",
    databaseURL: "https://ganapass-web.firebaseio.com",
    projectId: "ganapass-web",
    storageBucket: "ganapass-web.appspot.com",
    messagingSenderId: "314356495825",
    appId: "1:314356495825:web:d1ce3a1ac0e4bd48"
  },
  latamCredentials: {
    environment: "production",
    client_credentials: {
      clientId: "UNA_SOLUTIONS_PREPAGO",
      clientSecret: "UGrwQptZSGQp1PAeuPPk8GdrYpgK0XISXKxGTFBJ",
      accessTokenUri: "https://api.latam-pass.latam.com/oauth/token",
      scopes: [
        "accrual-create",
        "member-create",
        "transactions-show",
        "member-show-name",
        "member-show-status",
        "member-show-id-ffn",
        "member-show-id"
      ]
    }
  }
};
