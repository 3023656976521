<template>
  <div class="d-flex">
    <div class="card-campaign primary">
      <div class="card-promo">
        <div class="img-box relative">
          <v-img class="white--text img-card-prom" :src="category.banner_thumb"></v-img>
          <div
            style="
              position: absolute;
              width: 100px;
              height: 100px;
              top: 20px;
              left: 20px;
          "
          >
            <v-img class="white--text" :src="category.logo"></v-img>
          </div>
        </div>
        <div class="primary white--text">
          <v-card-title>
            <span class="title white--text text-xs-center w100 font-weight-bold" style="min-height: 40px;">
              {{ category.name }}
            </span>
            <div class="text-xs-left">
              <div class="my-4 campaign_description">{{ category.description }}</div>
            </div>
            <v-flex xs12 text-xs-center pt-3>
              <router-link :to="`/latam-pass/c/${category.id}`">
                <v-btn light color="accent" class="accent white--text">{{ category.button }}</v-btn>
              </router-link>
            </v-flex>
          </v-card-title>
        </div>
      </div>
    </div>
    <div class="xs12 content_rectangulo">
      <div class="rectangulo"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CardCategory",
  props: {
    category: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
