var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex" }, [
    _c("div", { staticClass: "card-campaign primary" }, [
      _c("div", { staticClass: "card-promo" }, [
        _c(
          "div",
          { staticClass: "img-box relative" },
          [
            _c("v-img", {
              staticClass: "white--text img-card-prom",
              attrs: { src: _vm.category.banner_thumb }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  position: "absolute",
                  width: "100px",
                  height: "100px",
                  top: "20px",
                  left: "20px"
                }
              },
              [
                _c("v-img", {
                  staticClass: "white--text",
                  attrs: { src: _vm.category.logo }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "primary white--text" },
          [
            _c(
              "v-card-title",
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "title white--text text-xs-center w100 font-weight-bold",
                    staticStyle: { "min-height": "40px" }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.category.name) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text-xs-left" }, [
                  _c("div", { staticClass: "my-4 campaign_description" }, [
                    _vm._v(_vm._s(_vm.category.description))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", "text-xs-center": "", "pt-3": "" } },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/latam-pass/c/" + _vm.category.id } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "accent white--text",
                            attrs: { light: "", color: "accent" }
                          },
                          [_vm._v(_vm._s(_vm.category.button))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "xs12 content_rectangulo" }, [
      _c("div", { staticClass: "rectangulo" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }