var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bannerAllowCookies
    ? _c(
        "div",
        {
          staticClass: "shadow-lg border-primary",
          staticStyle: {
            position: "fixed",
            bottom: "2%",
            "z-index": "1002",
            width: "90%",
            left: "5%"
          }
        },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", white: "", "pa-1": "" } },
            [
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    "pt-2": "",
                    "px-2": "",
                    "text-xs-left": ""
                  }
                },
                [
                  _vm._v(
                    "\n      Ganapass sirve cookies para analizar el tráfico a este sitio. Para obtener más información sobre las cookies que\n      utilizamos, consulte nuestra\n      "
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "underline",
                      attrs: { to: "/privacy-policy", target: "_blank" }
                    },
                    [_vm._v("política de privacidad")]
                  ),
                  _vm._v(".\n    ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { xs12: "", "text-xs-right": "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", small: "" },
                      on: { click: _vm.acceptTracking }
                    },
                    [_vm._v("Aceptar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", flat: "" },
                      on: {
                        click: function($event) {
                          _vm.bannerAllowCookies = false
                        }
                      }
                    },
                    [_vm._v("No aceptar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }