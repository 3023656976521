<template>
  <div class="d-flex">
    <div class="card-campaign primary">
      <div class="card-promo">
        <div class="img-box relative">
          <v-img class="white--text img-card-prom" :src="campaign.banner_thumb"></v-img>
          <div
            style="
              position: absolute;
              width: 100px;
              height: 100px;
              top: 20px;
              left: 20px;
          "
          >
            <v-img class="white--text" :src="campaign.brand.photo"></v-img>
          </div>
        </div>
        <div class="primary white--text">
          <v-card-title>
            <span class="title white--text font-weight-bold w100" style="min-height: 40px;">
              {{ campaign.name }}
            </span>
            <div class="text-xs-left">
              <div class="my-4 campaign_description">{{ campaign.resume }}</div>
            </div>
            <v-flex xs12 sm6 text-xs-center pt-3>
              <div class="font-weight-bold body-1">{{ campaign.caption }}</div>
            </v-flex>

            <v-flex xs12 sm6 text-xs-center pt-3>
              <router-link :to="`/latam-pass/${campaign.brand.id}/${campaign.id}`">
                <v-btn light color="accent" class="accent white--text">Participa aquí</v-btn>
              </router-link>
            </v-flex>
          </v-card-title>
        </div>
      </div>
    </div>
    <div class="xs12 content_rectangulo">
      <div class="rectangulo"></div>
    </div>
  </div>
</template>
<script>
import formatCurrency from "@/utils/format_currency";

export default {
  name: "CardCampaign",
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    formatCurrency(amount) {
      return formatCurrency(amount);
    },
  },
};
</script>
