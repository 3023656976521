import Vue from "vue";
import VueAnalytics from "vue-analytics";
import router from "@/router";
import config from "Config/config";

function activeTracking() {
  Vue.use(VueAnalytics, {
    id: config.ga,
    router,
    autoTracking: {
      skipSamePath: true,
      exception: true,
    },
  });
}

export default activeTracking;
