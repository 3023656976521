<template>
  <div id="root">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@font-face {
  font-family: "Latam-Sans-Regular";
  src: url("/assets/font/Latam/Latam_Sans_Regular.otf");
}
body,
p,
span,
.application,
.display-1,
.headline,
.title,
.subheading,
.caption {
  font-family: "Latam-Sans-Regular", arial, sans-serif !important;
}
#app {
  font-weight: 100;
  color: #4c4c4c;
  text-align: center;
}
a {
  text-decoration: none;
}
.detail-bottom::after {
  content: " ";
  height: 0;
  border-width: 0 0 15px 95vw !important;
  border-color: #ed1650 transparent transparent #ed1650 !important;
  border-style: solid !important;
  top: 100%;
  left: 0%;
  position: relative;
}
.detail-top::before {
  content: " ";
  height: 0;
  border-width: 0 95vw 15px 0 !important;
  border-color: #ed1650 transparent #ed1650 transparent !important;
  border-style: solid !important;
  top: 10%;
  left: 0%;
  position: relative;
}
.v-btn {
  text-transform: none;
  font-size: 16px;
}
.border-primary {
  border: 1px solid #1b0088;
}
.radius-8 {
  border-radius: 8px !important;
}
.mt-60 {
  margin-top: 60px;
}
@media only screen and (min-width: 1264px) {
  .container {
    max-width: 1185px !important;
  }
}
.content_rectangulo {
  height: 20px;
  margin-left: 15px;
  overflow: hidden;
  width: 80%;
  position: absolute;
  bottom: 1px;
  z-index: 0;
}
.rectangulo {
  background: #ed1650;
  height: 37px;
  margin: -31px auto 0 auto;
  -webkit-transform: rotate(-0.05rad);
  -ms-transform: rotate(-0.05rad);
  transform: rotate(-0.05rad);
}
</style>
