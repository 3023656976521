<template>
  <div>
    <v-toolbar id="navbar" color="white" fixed dark centered class="hidden-md-and-down" height="50">
      <div style="height:100%">
        <router-link :to="'/'">
          <img :src="require('@/assets/img/logos/logo_ganapass.svg')" style="height:100%" class="mx-2 py-2" />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <div class="hidden-sm-and-down">
        <div v-if="items" id="navbar--items" class="d-flex">
          <v-btn flat class="primary--text" @click="goToItem('#participate')">Participa</v-btn>
          <v-btn flat class="primary--text" @click="goToItem('#brands')">Marcas</v-btn>
          <v-btn flat class="primary--text" @click="goToItem('#about')">Nosotros</v-btn>
          <v-btn flat class="primary--text" @click="goToItem('#contact')">Contáctanos</v-btn>
        </div>
      </div>
      <div v-if="!user" class="hidden-md-and-down">
        <div class="d-flex">
          <router-link :to="'/login'">
            <v-btn flat class="primary--text">Inicia sesión</v-btn>
          </router-link>
          <router-link :to="'/register'">
            <v-btn color="accent">Regístrate</v-btn>
          </router-link>
        </div>
      </div>
      <div v-else class="hidden-md-and-down">
        <v-menu bottom left transition="slide-y-transition" class="my-auto">
          <div slot="activator" class="d-flex flex fill-height">
            <v-list-tile-avatar class="my-auto d-flex flex">
              <v-icon class="primary--text">person</v-icon>
              <span class="subheading text-capitalize primary--text pl-2">{{ user.name }}</span>
            </v-list-tile-avatar>
          </div>
          <v-list class="px-0" style="max-width: 300px">
            <v-layout row wrap>
              <v-flex xs12 py-3 px-1>
                <div class="text-xs-center caption primary--text">Usuario</div>
                <div class="text-xs-center font-weight-bold subheading mt-2 primary--text">
                  {{ user.name }} {{ user.lastname }}
                </div>
                <div class="text-xs-center caption primary--text">{{ user.email }}</div>
              </v-flex>
              <v-flex xs12>
                <v-divider></v-divider>
                <v-list-tile>
                  <router-link :to="'/profile'" class="d-flex">
                    <i class="material-icons pr-2" small>person</i>
                    <v-list-tile-title class="d-flex my-auto pt-0 body-1 black--text">Mi perfil</v-list-tile-title>
                  </router-link>
                </v-list-tile>
              </v-flex>
              <v-flex v-if="verifyPermissions(user.role, ['admin', 'super_admin'])" xs12>
                <v-divider></v-divider>
                <v-list-tile>
                  <router-link :to="'/dashboard'" class="d-flex">
                    <i class="material-icons pr-1" small>dashboard</i>
                    <v-list-tile-title class="d-flex my-auto pt-0 body-1 black--text"
                      >Panel de Control</v-list-tile-title
                    >
                  </router-link>
                </v-list-tile>
              </v-flex>
              <v-flex xs12>
                <v-divider></v-divider>
                <v-list-tile @click="logout">
                  <i class="material-icons pr-2 primary--text" small>exit_to_app</i>
                  <v-list-tile-title class="d-flex my-auto pt-0 body-1 black--text">Cerrar sesión</v-list-tile-title>
                </v-list-tile>
              </v-flex>
            </v-layout>
          </v-list>
        </v-menu>
      </div>
    </v-toolbar>
    <v-expansion-panel class="py-0 hidden-lg-and-up nav_mobile">
      <v-expansion-panel-content py-0 expand-icon="menu">
        <div slot="header">
          <router-link :to="'/'">
            <img :src="require('@/assets/img/logos/logo_ganapass.svg')" height="35" />
          </router-link>
          <router-link v-if="!user" :to="'/register'" class="pa-2" style="right: 47px;position: absolute;right: 56px;">
            <img :src="require('@/assets/img/landing/register-nav.png')" height="25" />
          </router-link>
        </div>
        <v-card>
          <v-layout align-center justify-center column fill-height>
            <div v-if="items" class="layout align-center justify-center column fill-height">
              <v-btn
                flat
                :class="activeItem === '#participate' ? 'v-btn--outline' : 'primary--text flex xs12'"
                @click="goToItem('#participate')"
                >Participa</v-btn
              >
              <v-btn
                flat
                :class="activeItem === '#brands' ? 'v-btn--outline' : 'primary--text flex xs12'"
                @click="goToItem('#brands')"
                >Marcas Participantes</v-btn
              >
              <v-btn
                flat
                :class="activeItem === '#about' ? 'v-btn--outline' : 'primary--text flex xs12'"
                @click="goToItem('#about')"
                >Nosotros</v-btn
              >
              <v-btn
                flat
                :class="activeItem === '#contact' ? 'v-btn--outline' : 'primary--text flex xs12'"
                @click="goToItem('#contact')"
                >Contáctanos</v-btn
              >
            </div>
            <v-layout v-if="!user" align-center justify-center column fill-height>
              <router-link :to="'/login'">
                <v-btn flat class="primary--text">Inicia sesión</v-btn>
              </router-link>
            </v-layout>
            <div v-else>
              <v-menu bottom left transition="slide-y-transition" class="my-auto">
                <div slot="activator" class="d-flex flex fill-height">
                  <v-list-tile-avatar class="my-auto d-flex flex">
                    <v-icon class="primary--text">person</v-icon>
                    <span class="text-capitalize primary--text pl-2">{{ user.name }}</span>
                  </v-list-tile-avatar>
                </div>
                <v-list class="px-0" style="max-width: 300px">
                  <v-layout row wrap>
                    <v-flex xs12 py-3 px-1>
                      <div class="text-xs-center caption primary--text">Usuario</div>
                      <div class="text-xs-center font-weight-bold subheading mt-2 primary--text">
                        {{ user.name }} {{ user.lastname }}
                      </div>
                      <div class="text-xs-center caption primary--text">{{ user.email }}</div>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                      <v-list-tile>
                        <router-link :to="'/profile'" class="d-flex">
                          <i class="material-icons pr-2" small>person</i>
                          <v-list-tile-title class="d-flex my-auto pt-0 body-1 black--text"
                            >Mi perfil</v-list-tile-title
                          >
                        </router-link>
                      </v-list-tile>
                    </v-flex>
                    <v-flex v-if="verifyPermissions(user.role, ['admin', 'super_admin'])" xs12>
                      <v-divider></v-divider>
                      <v-list-tile>
                        <router-link :to="'/dashboard'" class="d-flex">
                          <i class="material-icons pr-1" small>dashboard</i>
                          <v-list-tile-title class="d-flex my-auto pt-0 body-1 black--text"
                            >Panel de Control</v-list-tile-title
                          >
                        </router-link>
                      </v-list-tile>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                      <v-list-tile @click="logout">
                        <i class="material-icons pr-2 primary--text" small>exit_to_app</i>
                        <v-list-tile-title class="d-flex my-auto pt-0 body-1 black--text"
                          >Cerrar sesión</v-list-tile-title
                        >
                      </v-list-tile>
                    </v-flex>
                  </v-layout>
                </v-list>
              </v-menu>
            </div>
          </v-layout>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!-- Message no emailverified -->
    <div
      v-if="user && !user.emailVerified"
      class="shadow-lg border-primary"
      style="position: fixed; bottom: 2%; z-index: 1000; width: 90%; left: 5%;"
    >
      <v-layout row wrap white>
        <v-flex v-if="!sending" xs12 pa-2>
          Aún <span class="primary--text">no has verificado tu correo electrónico</span>. ¿Deseas que se te vuelva a
          enviar el correo de verificación a <span class="primary--text">{{ user.email }}</span
          >?
          <v-btn color="primary" small @click="sendVerificationMail">Reenviar correo</v-btn>
        </v-flex>
        <v-flex v-else xs12 pa-3>
          Se reenvió el link de verificación a tu correo electrónico
          <span class="primary--text">{{ user.email }}</span>
        </v-flex>
      </v-layout>
    </div>
    <AllowCookies />
  </div>
</template>

<script>
import { auth } from "@/config/firebase";
import { mapActions, mapGetters } from "vuex";

import AllowCookies from "@/components/banners/AllowCookies";

import sendVerificationMail from "@/utils/send_email";

export default {
  name: "Navbar",
  components: {
    AllowCookies,
  },
  props: {
    items: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: null,
      activeItem: "",
      sending: false,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  async created() {
    this.user = await auth.currentUser;
    await this.getInfo({ user: this.user });
    this.user = this.getUser;
    this.setUser();
  },
  methods: {
    ...mapActions(["getInfo", "logout"]),
    goToItem(item) {
      // this.activeItem = item;
      this.$emit("goTo", item);
    },
    setUser() {
      this.$emit("setUser", this.user);
    },
    sendVerificationMail() {
      this.sending = true;
      sendVerificationMail();
    },
    verifyPermissions(role, roles) {
      return roles.find((r) => {
        return r === role;
      });
    },
  },
};
</script>

<style>
#navbar {
  border-top: 8px solid #1b0088 !important;
}

.v-menu__activator {
  min-width: 100px !important;
}
.v-menu__activator .v-avatar {
  justify-content: flex-end;
}
.v-expansion-panel__header__icon i {
  color: #1b0088 !important;
}
.nav_mobile {
  position: fixed;
  z-index: 10;
}
.v-menu__content--fixed a {
  width: 100% !important;
}
@media (max-width: 768px) {
  .v-menu__activator .v-avatar {
    justify-content: center;
  }
  .v-menu__content--fixed {
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
  }
}
</style>
