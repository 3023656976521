<template>
  <!-- Banner appear to allow to use cookies -->
  <div
    v-if="bannerAllowCookies"
    class="shadow-lg border-primary"
    style="position: fixed; bottom: 2%; z-index: 1002; width: 90%; left: 5%;"
  >
    <v-layout row wrap white pa-1>
      <v-flex xs12 pt-2 px-2 text-xs-left>
        Ganapass sirve cookies para analizar el tráfico a este sitio. Para obtener más información sobre las cookies que
        utilizamos, consulte nuestra
        <router-link :to="'/privacy-policy'" target="_blank" class="underline">política de privacidad</router-link>.
      </v-flex>
      <v-flex xs12 text-xs-right>
        <v-btn color="primary" small @click="acceptTracking">Aceptar</v-btn>
        <v-btn small flat @click="bannerAllowCookies = false">No aceptar</v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import getCookie from "@/utils/get_cookie";
import activeTracking from "@/config/analytics";
import { auth } from "@/config/firebase";

export default {
  name: "AllowCookies",
  data() {
    return {
      bannerAllowCookies: true,
    };
  },
  async created() {
    this.bannerAllowCookies = !getCookie("_ga") && !auth.currentUser;
    if (!this.bannerAllowCookies) activeTracking();
  },
  methods: {
    acceptTracking() {
      this.bannerAllowCookies = false;
      activeTracking();
    },
  },
};
</script>
