import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import config from "Config/config";
// Initialize Firebase
firebase.initializeApp(config.app);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const usersCollection = db.collection("users");
const brandsCollection = db.collection("brands");
const campaignsCollection = db.collection("campaigns");
const recordsCollection = db.collection("records");
const categoriesCollection = db.collection("categories");
const rankingsCollection = db.collection("rankings");

const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
const routeApi =
  process.env.NODE_ENV === "development" ? "http://localhost:5000/project-passu/us-central1/api/api/v1" : "/api/v1";

export {
  db,
  auth,
  storage,
  usersCollection,
  brandsCollection,
  campaignsCollection,
  recordsCollection,
  categoriesCollection,
  rankingsCollection,
  serverTimestamp,
  routeApi,
};
