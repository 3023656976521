var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          staticClass: "hidden-md-and-down",
          attrs: {
            id: "navbar",
            color: "white",
            fixed: "",
            dark: "",
            centered: "",
            height: "50"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { height: "100%" } },
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _c("img", {
                  staticClass: "mx-2 py-2",
                  staticStyle: { height: "100%" },
                  attrs: {
                    src: require("@/assets/img/logos/logo_ganapass.svg")
                  }
                })
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("div", { staticClass: "hidden-sm-and-down" }, [
            _vm.items
              ? _c(
                  "div",
                  { staticClass: "d-flex", attrs: { id: "navbar--items" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary--text",
                        attrs: { flat: "" },
                        on: {
                          click: function($event) {
                            return _vm.goToItem("#participate")
                          }
                        }
                      },
                      [_vm._v("Participa")]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary--text",
                        attrs: { flat: "" },
                        on: {
                          click: function($event) {
                            return _vm.goToItem("#brands")
                          }
                        }
                      },
                      [_vm._v("Marcas")]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary--text",
                        attrs: { flat: "" },
                        on: {
                          click: function($event) {
                            return _vm.goToItem("#about")
                          }
                        }
                      },
                      [_vm._v("Nosotros")]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary--text",
                        attrs: { flat: "" },
                        on: {
                          click: function($event) {
                            return _vm.goToItem("#contact")
                          }
                        }
                      },
                      [_vm._v("Contáctanos")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.user
            ? _c("div", { staticClass: "hidden-md-and-down" }, [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/login" } },
                      [
                        _c(
                          "v-btn",
                          { staticClass: "primary--text", attrs: { flat: "" } },
                          [_vm._v("Inicia sesión")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      { attrs: { to: "/register" } },
                      [
                        _c("v-btn", { attrs: { color: "accent" } }, [
                          _vm._v("Regístrate")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _c(
                "div",
                { staticClass: "hidden-md-and-down" },
                [
                  _c(
                    "v-menu",
                    {
                      staticClass: "my-auto",
                      attrs: {
                        bottom: "",
                        left: "",
                        transition: "slide-y-transition"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex fill-height",
                          attrs: { slot: "activator" },
                          slot: "activator"
                        },
                        [
                          _c(
                            "v-list-tile-avatar",
                            { staticClass: "my-auto d-flex flex" },
                            [
                              _c("v-icon", { staticClass: "primary--text" }, [
                                _vm._v("person")
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "subheading text-capitalize primary--text pl-2"
                                },
                                [_vm._v(_vm._s(_vm.user.name))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list",
                        {
                          staticClass: "px-0",
                          staticStyle: { "max-width": "300px" }
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "py-3": "", "px-1": "" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-xs-center caption primary--text"
                                    },
                                    [_vm._v("Usuario")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-xs-center font-weight-bold subheading mt-2 primary--text"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.user.name) +
                                          " " +
                                          _vm._s(_vm.user.lastname) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-xs-center caption primary--text"
                                    },
                                    [_vm._v(_vm._s(_vm.user.email))]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-divider"),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { to: "/profile" }
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons pr-2",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("person")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-tile-title",
                                            {
                                              staticClass:
                                                "d-flex my-auto pt-0 body-1 black--text"
                                            },
                                            [_vm._v("Mi perfil")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.verifyPermissions(_vm.user.role, [
                                "admin",
                                "super_admin"
                              ])
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-divider"),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "d-flex",
                                              attrs: { to: "/dashboard" }
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass:
                                                    "material-icons pr-1",
                                                  attrs: { small: "" }
                                                },
                                                [_vm._v("dashboard")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-tile-title",
                                                {
                                                  staticClass:
                                                    "d-flex my-auto pt-0 body-1 black--text"
                                                },
                                                [_vm._v("Panel de Control")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-divider"),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-tile",
                                    { on: { click: _vm.logout } },
                                    [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons pr-2 primary--text",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v("exit_to_app")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-tile-title",
                                        {
                                          staticClass:
                                            "d-flex my-auto pt-0 body-1 black--text"
                                        },
                                        [_vm._v("Cerrar sesión")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-expansion-panel",
        { staticClass: "py-0 hidden-lg-and-up nav_mobile" },
        [
          _c(
            "v-expansion-panel-content",
            { attrs: { "py-0": "", "expand-icon": "menu" } },
            [
              _c(
                "div",
                { attrs: { slot: "header" }, slot: "header" },
                [
                  _c("router-link", { attrs: { to: "/" } }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/logos/logo_ganapass.svg"),
                        height: "35"
                      }
                    })
                  ]),
                  _vm._v(" "),
                  !_vm.user
                    ? _c(
                        "router-link",
                        {
                          staticClass: "pa-2",
                          staticStyle: { right: "56px", position: "absolute" },
                          attrs: { to: "/register" }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/landing/register-nav.png"),
                              height: "25"
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        "align-center": "",
                        "justify-center": "",
                        column: "",
                        "fill-height": ""
                      }
                    },
                    [
                      _vm.items
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "layout align-center justify-center column fill-height"
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  class:
                                    _vm.activeItem === "#participate"
                                      ? "v-btn--outline"
                                      : "primary--text flex xs12",
                                  attrs: { flat: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goToItem("#participate")
                                    }
                                  }
                                },
                                [_vm._v("Participa")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  class:
                                    _vm.activeItem === "#brands"
                                      ? "v-btn--outline"
                                      : "primary--text flex xs12",
                                  attrs: { flat: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goToItem("#brands")
                                    }
                                  }
                                },
                                [_vm._v("Marcas Participantes")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  class:
                                    _vm.activeItem === "#about"
                                      ? "v-btn--outline"
                                      : "primary--text flex xs12",
                                  attrs: { flat: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goToItem("#about")
                                    }
                                  }
                                },
                                [_vm._v("Nosotros")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  class:
                                    _vm.activeItem === "#contact"
                                      ? "v-btn--outline"
                                      : "primary--text flex xs12",
                                  attrs: { flat: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goToItem("#contact")
                                    }
                                  }
                                },
                                [_vm._v("Contáctanos")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.user
                        ? _c(
                            "v-layout",
                            {
                              attrs: {
                                "align-center": "",
                                "justify-center": "",
                                column: "",
                                "fill-height": ""
                              }
                            },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/login" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary--text",
                                      attrs: { flat: "" }
                                    },
                                    [_vm._v("Inicia sesión")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "v-menu",
                                {
                                  staticClass: "my-auto",
                                  attrs: {
                                    bottom: "",
                                    left: "",
                                    transition: "slide-y-transition"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex fill-height",
                                      attrs: { slot: "activator" },
                                      slot: "activator"
                                    },
                                    [
                                      _c(
                                        "v-list-tile-avatar",
                                        { staticClass: "my-auto d-flex flex" },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "primary--text" },
                                            [_vm._v("person")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-capitalize primary--text pl-2"
                                            },
                                            [_vm._v(_vm._s(_vm.user.name))]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    {
                                      staticClass: "px-0",
                                      staticStyle: { "max-width": "300px" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                "py-3": "",
                                                "px-1": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-xs-center caption primary--text"
                                                },
                                                [_vm._v("Usuario")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-xs-center font-weight-bold subheading mt-2 primary--text"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(_vm.user.name) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.user.lastname
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-xs-center caption primary--text"
                                                },
                                                [_vm._v(_vm._s(_vm.user.email))]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c("v-divider"),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-tile",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass: "d-flex",
                                                      attrs: { to: "/profile" }
                                                    },
                                                    [
                                                      _c(
                                                        "i",
                                                        {
                                                          staticClass:
                                                            "material-icons pr-2",
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("person")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list-tile-title",
                                                        {
                                                          staticClass:
                                                            "d-flex my-auto pt-0 body-1 black--text"
                                                        },
                                                        [_vm._v("Mi perfil")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.verifyPermissions(_vm.user.role, [
                                            "admin",
                                            "super_admin"
                                          ])
                                            ? _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c("v-divider"),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-tile",
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          staticClass: "d-flex",
                                                          attrs: {
                                                            to: "/dashboard"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "material-icons pr-1",
                                                              attrs: {
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "dashboard"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-list-tile-title",
                                                            {
                                                              staticClass:
                                                                "d-flex my-auto pt-0 body-1 black--text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Panel de Control"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c("v-divider"),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-tile",
                                                { on: { click: _vm.logout } },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "material-icons pr-2 primary--text",
                                                      attrs: { small: "" }
                                                    },
                                                    [_vm._v("exit_to_app")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-tile-title",
                                                    {
                                                      staticClass:
                                                        "d-flex my-auto pt-0 body-1 black--text"
                                                    },
                                                    [_vm._v("Cerrar sesión")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.user && !_vm.user.emailVerified
        ? _c(
            "div",
            {
              staticClass: "shadow-lg border-primary",
              staticStyle: {
                position: "fixed",
                bottom: "2%",
                "z-index": "1000",
                width: "90%",
                left: "5%"
              }
            },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", white: "" } },
                [
                  !_vm.sending
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "", "pa-2": "" } },
                        [
                          _vm._v("\n        Aún "),
                          _c("span", { staticClass: "primary--text" }, [
                            _vm._v("no has verificado tu correo electrónico")
                          ]),
                          _vm._v(
                            ". ¿Deseas que se te vuelva a\n        enviar el correo de verificación a "
                          ),
                          _c("span", { staticClass: "primary--text" }, [
                            _vm._v(_vm._s(_vm.user.email))
                          ]),
                          _vm._v("?\n        "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", small: "" },
                              on: { click: _vm.sendVerificationMail }
                            },
                            [_vm._v("Reenviar correo")]
                          )
                        ],
                        1
                      )
                    : _c("v-flex", { attrs: { xs12: "", "pa-3": "" } }, [
                        _vm._v(
                          "\n        Se reenvió el link de verificación a tu correo electrónico\n        "
                        ),
                        _c("span", { staticClass: "primary--text" }, [
                          _vm._v(_vm._s(_vm.user.email))
                        ])
                      ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("AllowCookies")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }