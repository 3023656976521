import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.css";
import zhHans from "vuetify/es5/locale/es";
import App from "./App";
import router from "./router";
import store from "./store";

import { auth } from "@/config/firebase";

Vue.config.productionTip = false;

// https://vuetifyjs.com/en/style/colors
Vue.use(Vuetify, {
  lang: {
    locales: { zhHans },
    current: "zhHans",
  },
  theme: {
    primary: "#1B0088", //#002D58", //"#014481", //#014481 1b0088
    secondary: "#10004F", //"#236DF2", //"#2d34ce",
    accent: "#E9124C", //"#3B7ACB", // "#006cc4", // "#009FFD",
    grey: "#e1e1e1",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
  },
});

// eslint-disable-next-line
auth.onAuthStateChanged((user) => {
  new Vue({
    el: "#root",
    router,
    store,
    components: { App },
    template: "<App/>",
  });
});
