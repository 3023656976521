import { auth } from "@/config/firebase";
import getCookie from "@/utils/get_cookie";

function App() {
  var startApp = function() {
    // Bind events.
    auth.onAuthStateChanged(this.onAuthStateChanged.bind(this));
  };
  document.addEventListener("DOMContentLoaded", startApp.bind(this));
}

// Triggered on Firebase auth state change.
App.prototype.onAuthStateChanged = function(user) {
  if (user) {
    this.verifySignOut();
    this.createAuthCookie();
  } else {
    this.removeAuthCookie();
  }
};

// SignOut From console (Send Cookie)
App.prototype.verifySignOut = async function() {
  const activeSignOut = await getCookie("__signOutStatus");
  if (activeSignOut === "true") {
    document.cookie = "__signOutStatus=";
    auth.signOut();
  }
};

// Create a __session cookie.
App.prototype.createAuthCookie = function() {
  var reqToke = function(token) {
    // set the __session cookie
    document.cookie = `__session=${token};max-age=3600`;
  };
  auth.currentUser.getIdToken(true).then(reqToke.bind(this));
};

// Remove __session cookie.
App.prototype.removeAuthCookie = function() {
  // Delete the __session cookie.
  document.cookie = "__session=";
};

//Load App
window.app = new App();
