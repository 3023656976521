import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=2c56dd1c&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/hosting/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2c56dd1c')) {
      api.createRecord('2c56dd1c', component.options)
    } else {
      api.reload('2c56dd1c', component.options)
    }
    module.hot.accept("./Footer.vue?vue&type=template&id=2c56dd1c&", function () {
      api.rerender('2c56dd1c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/inc/Footer.vue"
export default component.exports