var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    { attrs: { dark: "", color: "white", height: "auto" } },
    [
      _c(
        "v-layout",
        { staticClass: "border-top-accent", attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "xs12 primary", attrs: { flat: "", tile: "" } },
            [
              _c(
                "v-card-title",
                { staticClass: "pa-1" },
                [
                  _c(
                    "v-container",
                    { staticClass: "px-4 fluid py-0" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                "py-1": "",
                                "text-xs-center": "",
                                "hidden-md-and-up": ""
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-medium title text-xs-center font-italic font-weight-bold"
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/logos/logo-una.png"),
                                      height: "40"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                "py-1": "",
                                "text-xs-center": "",
                                "hidden-md-and-up": ""
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-medium title text-xs-center font-italic font-weight-bold"
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/logos/logo_latam_white.png"),
                                      height: "45"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                md6: "",
                                "d-flex": "",
                                "text-xs-center": "",
                                "text-md-left": ""
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "font-weight-medium my-auto" },
                                [
                                  _vm._v(
                                    "\n                ©2019 — Powered by \n                "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "white--text font-weight-bold",
                                      staticStyle: {
                                        "text-decoration": "none"
                                      },
                                      attrs: {
                                        href: "http://www.unasolutions.com/",
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v("UNA Solutions")]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { xs12: "", md6: "" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "font-weight-medium title text-xs-center text-sm-right font-italic font-weight-bold hidden-sm-and-down"
                              },
                              [
                                _c("img", {
                                  staticClass: "mx-2",
                                  attrs: {
                                    src: require("@/assets/img/logos/logo-una.png"),
                                    height: "40"
                                  }
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "mx-2",
                                  attrs: {
                                    src: require("@/assets/img/logos/logo_latam_white.png"),
                                    height: "45"
                                  }
                                })
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }